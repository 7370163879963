import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ItemTypes } from "../../helpers/Constants";
import {
  AddCartPayload,
  ItemClickPayload,
  SetCategories,
  SetCategorizedProducts,
} from "../../types/PayloadActionTypes";
import {
  CartItem,
  CategorizedProducts,
  Category,
  Order,
  ProductInterface,
} from "../../types/Generic";
import { get } from "lockr";

export enum MODAL_TRIGGER {
  CLOSE,
  CART,
  ITEM_VIEW,
  BUY_OPTIONS,
  LANGUAGE,
}
export enum OrderSubmitState {
  Stale,
  Requested,
  Successful,
  Failed,
}
export interface Modal {
  isOpen: boolean;
  triggeredBy: MODAL_TRIGGER;
}

type CustomerOrders = Array<{
  orderTotal: number;
  orderNumber: number;
  order: Order;
}>;
interface Items {
  itemType: number;
  categorizedProducts: CategorizedProducts;
  activeProductCategories: Category[];
  filteredCategory: Category[];
  cart: CartItem[];
  activeItem: ProductInterface;
  modal: Modal;
  orderSubmitState: number;
  favorites: string[];
  highlightedFilter: string | any;
  customerOrders: CustomerOrders;
}

const initialState: Items = {
  itemType: ItemTypes.Drink,
  categorizedProducts: {
    drink: [{ id: "", product: [], name: "" }],
    food: [{ id: "", product: [], name: "" }],
  },
  activeProductCategories: [],
  filteredCategory: [],
  cart: [],
  activeItem: {
    categoryId: "",
    descriptions: {},
    inStock: false,
    prices: {},
    id: "",
    names: {},
    photo: "",
    price: 0,
  },
  modal: {
    isOpen: false,
    triggeredBy: MODAL_TRIGGER.CART,
  },
  customerOrders: [],
  orderSubmitState: OrderSubmitState.Stale,
  favorites: get("favorites"),
  highlightedFilter: "",
};

const ItemsSlice = createSlice({
  name: "items",
  initialState,
  reducers: {
    setCategorizedProducts: (
      state,
      action: PayloadAction<SetCategorizedProducts>
    ) => {
      state.categorizedProducts = action.payload.categorizedProducts;
      state.activeProductCategories =
        action.payload.initialActiveProductCategory;
    },

    setCategories: (state, action: PayloadAction<SetCategories>) => {
      const { payload } = action;
      if (payload.isSuccess && payload.categories) {
      }
    },
    setActiveCategory: (
      state,
      action: PayloadAction<{ activeCategory: Category[]; itemType: number }>
    ) => {
      state.activeProductCategories = action.payload.activeCategory;
      state.itemType = action.payload.itemType;
    },
    addCart: (state, action: PayloadAction<AddCartPayload>) => {
      const { cart } = action.payload;
      state.cart = cart;
    },
    manageModal: (state, action: PayloadAction<Modal>) => {
      state.modal = action.payload;
    },
    setActiveItem: (state, action: PayloadAction<ItemClickPayload>) => {
      const { item, modalPayload } = action.payload;
      state.modal = modalPayload;
      state.activeItem = item;
      return state;
    },
    setOrderSubmitState: (state, action: PayloadAction<number>) => {
      state.orderSubmitState = action.payload;
      return state;
    },
    setFavorites: (state, action: PayloadAction<string[]>) => {
      state.favorites = action.payload;
      return state;
    },
    setHighlightedFilter: (state, action: PayloadAction<string>) => {
      state.highlightedFilter = action.payload;
    },
    setCustomerOrders: (state, action: PayloadAction<CustomerOrders>) => {
      state.customerOrders = action.payload;
    },
  },
});

export const {
  setCategorizedProducts,
  setCategories,
  addCart,
  manageModal,
  setActiveCategory,
  setActiveItem,
  setOrderSubmitState,
  setFavorites,
  setHighlightedFilter,
  setCustomerOrders,
} = ItemsSlice.actions;
export default ItemsSlice.reducer;

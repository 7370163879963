import { configureStore } from "@reduxjs/toolkit";
import Items from "./slices/Items";
import Client from "./slices/Client";

export const store = configureStore({
  reducer: {
    Items,
    Client,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

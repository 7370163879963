import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../assets/Menutender.png";

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f9fa;
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: 20px;
  animation: ${bounce} 1.5s infinite ease-in-out;
`;

const LoadingText = styled.p`
  margin-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #343333;
  animation: ${bounce} 2s infinite ease-in-out;
`;

const LoadingScreen: React.FC = () => (
  <LoadingContainer>
    <Logo src={logo} alt="Company Logo" />
    <LoadingText>Ready to elevate your customer experience...</LoadingText>
  </LoadingContainer>
);

export default LoadingScreen;
